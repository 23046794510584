import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { ButtonExtLink } from 'components/Shared/_Links'

import { formsMenu } from 'constant/member-forms.constant'
// import { formsMenu } from 'constant/member/forms'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.section`
  width: 100%;
  min-height: 800px;
  --column-count: 2;
  ${p => p.theme.mq.md`
    --column-count: 3;
  `}
  --column-gap: 2vw;
  column-count: var(--column-count);
  column-gap: var(--column-gap);
  margin-bottom: calc(var(--margin-default)*2.5);

  div {
    border: 0.05rem solid var(--color-gray-medium);
    margin-bottom: var(--column-gap);
    padding: var(--margin-default) 0;
    break-inside: avoid;
    background-image: var(--gradient-btn);

    h3 {
      margin-bottom: 2rem;
    }
    a {
      display: block;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 2rem;
      --border-radius: 1rem;
      font-size: calc(var(--font-size-default) * 0.9);
      opacity: 0.9;
    }
  }
`
//#endregion

const Forms = ({ data }) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.form
  return (
    <PageContent>
      <Seo title={title} description={description} />
      <Title>各式表格</Title>
      <Wrapper>
        {formsMenu.map((item, i) => {
          return (
            <div key={`menu-${i}`}>
              <h3>{item.category}</h3>
              <ul>
                {item.menu.map((itm, i) => {
                  return (
                    <li key={`item-${i}`}>
                      <ButtonExtLink href={itm.file} target='_blank'>
                        {itm.label}
                      </ButtonExtLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </Wrapper>
    </PageContent>
  )
}

export default Forms

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        form {
          title
          description
        }
      }
    }
  }
`
