import uniform from 'assets/files/member/uniform.pdf'
import agreement from 'assets/files/member/量製團服同意書.pdf'
import leaveform from 'assets/files/member/請假單.pdf'
import quitform from 'assets/files/member/離團申請書.pdf'
import penalty from 'assets/files/member/違規紀錄通知書.pdf'

export const formsMenu = [
  {
    category: '晶晶團服',
    menu: [
      {
        file: uniform,
        label: '晶晶團服款式公告'
      },
      {
        file: agreement,
        label: '量製團服同意書'
      }
    ]
  },
  {
    category: '團員事務',
    menu: [
      {
        file: leaveform,
        label: '請假單'
      },
      {
        file: quitform,
        label: '離團申請書'
      }
    ]
  },
  {
    category: '其他表格',
    menu: [
      {
        file: penalty,
        label: '違規記錄通知書'
      },
    ]
  },

]